import Account from "./subcomponents/Account";
import Home from "./subcomponents/Home";
import Login from "./subcomponents/Login";
import Register from "./subcomponents/Register";
import Settings from "./subcomponents/Settings";

class Components {
    constructor() {
        this.Account = Account;
        this.Home = Home;
        this.Login = Login;
        this.Register = Register;
        this.Settings = Settings;
    }
}

export default Components;
export { Account, Home, Login, Register, Settings };
