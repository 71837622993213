import React, { useState, useEffect } from "react";
import DeleteModal from "../DeleteModal";
import { HideDoubleAnimation } from "../Animations/HideAnimation";
import { ShowDoubleAnimation } from "../Animations/ShowAnimation";
import { Send, Render } from "pfr-utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

const Patient = ({
    looks,
    ownerName,
    patients,
    selectPatient,
    removePatient,
    newUsage,
    validation,
    reRenderParentCallback,
}) => {
    const render = Render();
    const [patientList, setPatientList] = useState(patients);
    const [deleteTarget, setDeleteTarget] = useState({ label: "", uuid: "" });
    const [inputDisplay, setInputDisplay] = useState(false);

    const patientDelete = (e) => {
        console.debug(deleteTarget);
        validation.display = true;
        validation.message = "Patient deleted.";
        validation.isValid = "warning";
        const profileKey = window.location.pathname.replace(/\//g, "");
        const payload = {
            profileKey: profileKey,
            deleteTarget: deleteTarget,
        };
        Send("medicine-profile/delete-patient", payload, (response) => {
            console.debug(response);
        });
        setPatientList(Object.values(patientList).filter((d) => d.uuid !== deleteTarget.uuid));
        let selectedPatient = "";
        selectPatient(selectedPatient);
        reRenderParentCallback();
    };
    const addPatient = (e) => {
        // Reset Input Validation
        document.getElementById("someoneNew").classList.remove("border-success");
        document.getElementById("someoneNew").classList.remove("border-danger");
        document.getElementById("someoneNew").classList.remove("is-invalid");
        document.getElementById("someoneNew").classList.remove("is-valid");

        const addPatientInput = document.getElementById("someoneNew");
        if (addPatientInput.value.length === 0 || addPatientInput.value.length > 255) {
            addPatientInput.classList.add("is-invalid");
            validation.display = true;
            validation.message =
                "Patient name must be longer than 0 characters and shorter than 255.";
            validation.isValid = "danger";
            reRenderParentCallback();
            console.debug("is invalid");
            return;
        }
        addPatientInput.classList.remove("is-invalid");
        const profileKey = window.location.pathname.replace(/\//g, "");
        const payload = {
            profileKey: profileKey,
            patientName: addPatientInput.value,
        };
        validation.display = true;
        validation.message = "New patient added.";
        validation.isValid = "success";
        reRenderParentCallback();

        Send("medicine-profile/add-patient", payload, (response) => {
            if (response.result === "success") {
                const newPatient = response.newPatient;
                console.debug(newPatient);
                setPatientList({ ...patientList, ...newPatient });
                selectPatient(Object.values(newPatient)[0].label);
            }
        });
        document.getElementById("someoneNew").value = "";
        setInputDisplay(false);
    };

    const showAnimation = () => {
        const element1 = document.getElementById("someoneNew");
        const element2 = document.getElementById("patientAdd");
        const condition = element1.style.display === "none";
        const animation = "slideInRight";
        ShowDoubleAnimation({ element1, element2, condition, animation });
        setInputDisplay(true);
    };

    const hideAnimation = () => {
        const element1 = document.getElementById("patientAdd");
        const element2 = document.getElementById("someoneNew");
        const condition = element1.style.display === "";
        const animation = "slideOutRight";
        HideDoubleAnimation({ element1, element2, condition, animation });
        setInputDisplay(false);
    };

    return (
        <span>
            {/* Medicine User Card */}
            <h5 className="d-flex">
                Choose Patient
                {/* Current User Badge */}
                <div id="patientBadge" style={{ display: "" }}>
                    <span
                        className="badge badge-primary ml-2 rounded-0 font-weight-normal animated fadeIn"
                        id="active-record-timestamp"
                        style={looks}>
                        {newUsage.consumer}
                        <sup className="pl-1">
                            <span
                                className="deleteButton"
                                id={"patient-badge"}
                                onClick={(e) => {
                                    let selectedPatient = "";
                                    document
                                        .getElementById("active-record-timestamp")
                                        .classList.add("fadeOut");
                                    removePatient(selectedPatient);
                                }}
                                style={{
                                    fontSize: "small",
                                    cursor: "pointer",
                                }}>
                                <FontAwesomeIcon className="mt-1 ml-1" icon={faTimesCircle} />
                            </span>
                        </sup>
                    </span>
                </div>
            </h5>
            <div className="d-flex flex-wrap">
                <span className="btn-group mr-2 flex-wrap" role="group">
                    <button
                        id={"user" + ownerName}
                        type="button"
                        className="setButton btn btn-primary mr-1 mb-1 rounded-0"
                        style={looks}
                        data-label={ownerName}
                        onClick={(e) => {
                            let selectedPatient = e.target.dataset.label;
                            document
                                .getElementById("active-record-timestamp")
                                .classList.add("fadeIn");
                            selectPatient(selectedPatient);
                        }}>
                        {ownerName}
                    </button>
                    {/* Map through patients prop and return a button with each user name */}

                    {patientList !== undefined ? (
                        Object.values(patientList).map((_patient, index) => {
                            if (_patient.label !== "") {
                                return (
                                    <span className="btn-group mr-1 mb-1" style={{ zIndex: "1" }}>
                                        <span
                                            type="button"
                                            className="btn btn-primary d-inline-flex px-1 rounded-0"
                                            data-label={_patient.label}
                                            onClick={(e) => {
                                                let selectedPatient = e.target.dataset.label;
                                                document
                                                    .getElementById("active-record-timestamp")
                                                    .classList.add("fadeIn");
                                                selectPatient(selectedPatient);
                                            }}
                                            style={looks}>
                                            {_patient.label}
                                        </span>
                                        <button
                                            type="button"
                                            className="btn btn-primary rounded-0 px-1"
                                            data-toggle="modal"
                                            data-uuid={_patient.uuid}
                                            data-label={_patient.label}
                                            onClick={(e) => {
                                                setDeleteTarget({
                                                    label: e.currentTarget.dataset.label,
                                                    uuid: e.currentTarget.dataset.uuid,
                                                });
                                            }}
                                            data-target="#delete-modal-patient"
                                            style={{ ...looks, zIndex: "2" }}>
                                            <sup className="pr-1">
                                                <span
                                                    className="deleteButton"
                                                    data-uuid={_patient.uuid}
                                                    data-label={_patient.label}
                                                    id={_patient.uuid}
                                                    style={{
                                                        color: "white",
                                                        fontSize: "small",
                                                    }}>
                                                    <FontAwesomeIcon
                                                        className="mt-1 ml-1"
                                                        icon={faTimesCircle}
                                                    />
                                                </span>
                                            </sup>
                                        </button>
                                    </span>
                                );
                            }
                            return;
                        })
                    ) : (
                        <span />
                    )}
                </span>
            </div>
            <span className="d-flex">
                <div className="input-group mt-1">
                    <div className="input-group-prepend">
                        <button
                            className="btn btn-primary rounded-0"
                            type="button"
                            onClick={(e) => {
                                inputDisplay ? hideAnimation(e) : showAnimation(e);
                                document
                                    .getElementById("someoneNew")
                                    .classList.remove("is-invalid");
                            }}
                            style={{ ...looks, ...{ zIndex: "1" } }}>
                            <FontAwesomeIcon icon={inputDisplay ? faTimes : faPlus} size="sm" />
                            <span className="ml-1">{inputDisplay ? "Close" : "Add"}</span>
                        </button>
                    </div>
                    <input
                        autoComplete="off"
                        id="someoneNew"
                        className="form-control border-2 border-success animated"
                        type="text"
                        placeholder="Patient Name."
                        style={{
                            display: "none",
                        }}
                        onFocus={(e) => {
                            e.target.classList.remove("border-success");
                        }}
                        onKeyUp={(e) => {
                            e.preventDefault();
                            if (e.keyCode === 13) {
                                addPatient(e);
                            }
                        }}
                    />
                    <div className="input-group-append">
                        <button
                            className="btn btn-primary rounded-0 animated"
                            id="patientAdd"
                            style={{ ...looks, display: "none" }}
                            onClick={addPatient}>
                            <FontAwesomeIcon icon={faPlus} size="sm" />
                            <span className="ml-1">Add</span>
                        </button>
                    </div>
                    <div className="invalid-feedback" id="someoneNew-invalid-feedback">
                        Please Enter A Name
                    </div>
                </div>
            </span>
            <DeleteModal id="patient" deleteEvent={patientDelete} deleteTarget={deleteTarget} />
        </span>
    );
};
export default Patient;
