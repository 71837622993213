import React from "react";
import { InputGroup, Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// TODO : Add "Placeholder" functionality for when the inputs value is ""

export default function InputField({
    // ref,
    id,
    label,
    icon,
    defaultValue,
    displaySave,
    onSave,
    display = "block",
    as = "input",
    type = "text",
    eventDispatchers = {},
    validationMessages = {
        valid: false,
        invalid: false,
    },
}) {
    // prettier-ignore
    const fn = () => {return};
    console.log(displaySave);
    return (
        <div style={{ display: display }}>
            <Form.Label>{label}</Form.Label>
            {/* Input Group to allow prepending icon */}
            <InputGroup>
                <InputGroup.Prepend>
                    <InputGroup.Text>
                        <FontAwesomeIcon icon={icon} />
                    </InputGroup.Text>
                </InputGroup.Prepend>

                {/* Input */}
                {/* prettier-ignore */}
                <Form.Control
                    as={as}
                    id={id}
                    type={type}
                    defaultValue={defaultValue}
                    // ref={ref}
                    onAbort={eventDispatchers.onAbort || fn}
                    onAbortCapture={eventDispatchers.onAbortCapture || fn}
                    onAnimationEnd={eventDispatchers.onAnimationEnd || fn}
                    onAnimationEndCapture={eventDispatchers.onAnimationEndCapture || fn}
                    onAnimationIteration={eventDispatchers.onAnimationIteration || fn}
                    onAnimationIterationCapture={eventDispatchers.onAnimationIterationCapture || fn}
                    onAnimationStart={eventDispatchers.onAnimationStart || fn}
                    onAnimationStartCapture={eventDispatchers.onAnimationStartCapture || fn}
                    onAuxClick={eventDispatchers.onAuxClick || fn}
                    onAuxClickCapture={eventDispatchers.onAuxClickCapture || fn}
                    onBeforeInput={eventDispatchers.onBeforeInput || fn}
                    onBeforeInputCapture={eventDispatchers.onBeforeInputCapture || fn}
                    onBlur={eventDispatchers.onBlur || fn}
                    onBlurCapture={eventDispatchers.onBlurCapture || fn}
                    onCanPlay={eventDispatchers.onCanPlay || fn}
                    onCanPlayCapture={eventDispatchers.onCanPlayCapture || fn}
                    onCanPlayThrough={eventDispatchers.onCanPlayThrough || fn}
                    onCanPlayThroughCapture={eventDispatchers.onCanPlayThroughCapture || fn}
                    onChange={eventDispatchers.onChange || fn}
                    onChangeCapture={eventDispatchers.onChangeCapture || fn}
                    onClick={eventDispatchers.onClick || fn}
                    onClickCapture={eventDispatchers.onClickCapture || fn}
                    onCompositionEnd={eventDispatchers.onCompositionEnd || fn}
                    onCompositionEndCapture={eventDispatchers.onCompositionEndCapture || fn}
                    onCompositionStart={eventDispatchers.onCompositionStart || fn}
                    onCompositionStartCapture={eventDispatchers.onCompositionStartCapture || fn}
                    onCompositionUpdate={eventDispatchers.onCompositionUpdate || fn}
                    onCompositionUpdateCapture={eventDispatchers.onCompositionUpdateCapture || fn}
                    onContextMenu={eventDispatchers.onContextMenu || fn}
                    onContextMenuCapture={eventDispatchers.onContextMenuCapture || fn}
                    onCopy={eventDispatchers.onCopy || fn}
                    onCopyCapture={eventDispatchers.onCopyCapture || fn}
                    onCut={eventDispatchers.onCut || fn}
                    onCutCapture={eventDispatchers.onCutCapture || fn}
                    onDoubleClick={eventDispatchers.onDoubleClick || fn}
                    onDoubleClickCapture={eventDispatchers.onDoubleClickCapture || fn}
                    onDrag={eventDispatchers.onDrag || fn}
                    onDragCapture={eventDispatchers.onDragCapture || fn}
                    onDragEnd={eventDispatchers.onDragEnd || fn}
                    onDragEndCapture={eventDispatchers.onDragEndCapture || fn}
                    onDragEnter={eventDispatchers.onDragEnter || fn}
                    onDragEnterCapture={eventDispatchers.onDragEnterCapture || fn}
                    onDragExit={eventDispatchers.onDragExit || fn}
                    onDragExitCapture={eventDispatchers.onDragExitCapture || fn}
                    onDragLeave={eventDispatchers.onDragLeave || fn}
                    onDragLeaveCapture={eventDispatchers.onDragLeaveCapture || fn}
                    onDragOver={eventDispatchers.onDragOver || fn}
                    onDragOverCapture={eventDispatchers.onDragOverCapture || fn}
                    onDragStart={eventDispatchers.onDragStart || fn}
                    onDragStartCapture={eventDispatchers.onDragStartCapture || fn}
                    onDrop={eventDispatchers.onDrop || fn}
                    onDropCapture={eventDispatchers.onDropCapture || fn}
                    onDurationChange={eventDispatchers.onDurationChange || fn}
                    onDurationChangeCapture={eventDispatchers.onDurationChangeCapture || fn}
                    onEmptied={eventDispatchers.onEmptied || fn}
                    onEmptiedCapture={eventDispatchers.onEmptiedCapture || fn}
                    onEncrypted={eventDispatchers.onEncrypted || fn}
                    onEncryptedCapture={eventDispatchers.onEncryptedCapture || fn}
                    onEnded={eventDispatchers.onEnded || fn}
                    onEndedCapture={eventDispatchers.onEndedCapture || fn}
                    onError={eventDispatchers.onError || fn}
                    onErrorCapture={eventDispatchers.onErrorCapture || fn}
                    onFocus={eventDispatchers.onFocus || fn}
                    onFocusCapture={eventDispatchers.onFocusCapture || fn}
                    onGotPointerCapture={eventDispatchers.onGotPointerCapture || fn}
                    onGotPointerCaptureCapture={eventDispatchers.onGotPointerCaptureCapture || fn}
                    onInput={eventDispatchers.onInput || fn}
                    onInputCapture={eventDispatchers.onInputCapture || fn}
                    onInvalid={eventDispatchers.onInvalid || fn}
                    onInvalidCapture={eventDispatchers.onInvalidCapture || fn}
                    onKeyDown={eventDispatchers.onKeyDown || fn}
                    onKeyDownCapture={eventDispatchers.onKeyDownCapture || fn}
                    onKeyPress={eventDispatchers.onKeyPress || fn}
                    onKeyPressCapture={eventDispatchers.onKeyPressCapture || fn}
                    onKeyUp={eventDispatchers.onKeyUp || fn}
                    onKeyUpCapture={eventDispatchers.onKeyUpCapture || fn}
                    onLoad={eventDispatchers.onLoad || fn}
                    onLoadCapture={eventDispatchers.onLoadCapture || fn}
                    onLoadStart={eventDispatchers.onLoadStart || fn}
                    onLoadStartCapture={eventDispatchers.onLoadStartCapture || fn}
                    onLoadedData={eventDispatchers.onLoadedData || fn}
                    onLoadedDataCapture={eventDispatchers.onLoadedDataCapture || fn}
                    onLoadedMetadata={eventDispatchers.onLoadedMetadata || fn}
                    onLoadedMetadataCapture={eventDispatchers.onLoadedMetadataCapture || fn}
                    onLostPointerCapture={eventDispatchers.onLostPointerCapture || fn}
                    onLostPointerCaptureCapture={eventDispatchers.onLostPointerCaptureCapture || fn}
                    onMouseDown={eventDispatchers.onMouseDown || fn}
                    onMouseDownCapture={eventDispatchers.onMouseDownCapture || fn}
                    onMouseEnter={eventDispatchers.onMouseEnter || fn}
                    onMouseLeave={eventDispatchers.onMouseLeave || fn}
                    onMouseMove={eventDispatchers.onMouseMove || fn}
                    onMouseMoveCapture={eventDispatchers.onMouseMoveCapture || fn}
                    onMouseOut={eventDispatchers.onMouseOut || fn}
                    onMouseOutCapture={eventDispatchers.onMouseOutCapture || fn}
                    onMouseOver={eventDispatchers.onMouseOver || fn}
                    onMouseOverCapture={eventDispatchers.onMouseOverCapture || fn}
                    onMouseUp={eventDispatchers.onMouseUp || fn}
                    onMouseUpCapture={eventDispatchers.onMouseUpCapture || fn}
                    onPaste={eventDispatchers.onPaste || fn}
                    onPasteCapture={eventDispatchers.onPasteCapture || fn}
                    onPause={eventDispatchers.onPause || fn}
                    onPauseCapture={eventDispatchers.onPauseCapture || fn}
                    onPlay={eventDispatchers.onPlay || fn}
                    onPlayCapture={eventDispatchers.onPlayCapture || fn}
                    onPlaying={eventDispatchers.onPlaying || fn}
                    onPlayingCapture={eventDispatchers.onPlayingCapture || fn}
                    onPointerCancel={eventDispatchers.onPointerCancel || fn}
                    onPointerCancelCapture={eventDispatchers.onPointerCancelCapture || fn}
                    onPointerDown={eventDispatchers.onPointerDown || fn}
                    onPointerDownCapture={eventDispatchers.onPointerDownCapture || fn}
                    onPointerEnter={eventDispatchers.onPointerEnter || fn}
                    // onPointerEnterCapture={eventDispatchers.onPointerEnterCapture || fn}
                    onPointerLeave={eventDispatchers.onPointerLeave || fn}
                    // onPointerLeaveCapture={eventDispatchers.onPointerLeaveCapture || fn}
                    onPointerMove={eventDispatchers.onPointerMove || fn}
                    onPointerMoveCapture={eventDispatchers.onPointerMoveCapture || fn}
                    onPointerOut={eventDispatchers.onPointerOut || fn}
                    onPointerOutCapture={eventDispatchers.onPointerOutCapture || fn}
                    onPointerOver={eventDispatchers.onPointerOver || fn}
                    onPointerOverCapture={eventDispatchers.onPointerOverCapture || fn}
                    onPointerUp={eventDispatchers.onPointerUp || fn}
                    onPointerUpCapture={eventDispatchers.onPointerUpCapture || fn}
                    onProgress={eventDispatchers.onProgress || fn}
                    onProgressCapture={eventDispatchers.onProgressCapture || fn}
                    onRateChange={eventDispatchers.onRateChange || fn}
                    onRateChangeCapture={eventDispatchers.onRateChangeCapture || fn}
                    onReset={eventDispatchers.onReset || fn}
                    onResetCapture={eventDispatchers.onResetCapture || fn}
                    onScroll={eventDispatchers.onScroll || fn}
                    onScrollCapture={eventDispatchers.onScrollCapture || fn}
                    onSeeked={eventDispatchers.onSeeked || fn}
                    onSeekedCapture={eventDispatchers.onSeekedCapture || fn}
                    onSeeking={eventDispatchers.onSeeking || fn}
                    onSeekingCapture={eventDispatchers.onSeekingCapture || fn}
                    onSelect={eventDispatchers.onSelect || fn}
                    onSelectCapture={eventDispatchers.onSelectCapture || fn}
                    onStalled={eventDispatchers.onStalled || fn}
                    onStalledCapture={eventDispatchers.onStalledCapture || fn}
                    onSubmit={eventDispatchers.onSubmit || fn}
                    onSubmitCapture={eventDispatchers.onSubmitCapture || fn}
                    onSuspend={eventDispatchers.onSuspend || fn}
                    onSuspendCapture={eventDispatchers.onSuspendCapture || fn}
                    onTimeUpdate={eventDispatchers.onTimeUpdate || fn}
                    onTimeUpdateCapture={eventDispatchers.onTimeUpdateCapture || fn}
                    onTouchCancel={eventDispatchers.onTouchCancel || fn}
                    onTouchCancelCapture={eventDispatchers.onTouchCancelCapture || fn}
                    onTouchEnd={eventDispatchers.onTouchEnd || fn}
                    onTouchEndCapture={eventDispatchers.onTouchEndCapture || fn}
                    onTouchMove={eventDispatchers.onTouchMove || fn}
                    onTouchMoveCapture={eventDispatchers.onTouchMoveCapture || fn}
                    onTouchStart={eventDispatchers.onTouchStart || fn}
                    onTouchStartCapture={eventDispatchers.onTouchStartCapture || fn}
                    onTransitionEnd={eventDispatchers.onTransitionEnd || fn}
                    onTransitionEndCapture={eventDispatchers.onTransitionEndCapture || fn}
                    onVolumeChange={eventDispatchers.onVolumeChange || fn}
                    onVolumeChangeCapture={eventDispatchers.onVolumeChangeCapture || fn}
                    onWaiting={eventDispatchers.onWaiting || fn}
                    onWaitingCapture={eventDispatchers.onWaitingCapture || fn}
                    onWheel={eventDispatchers.onWheel || fn}
                    onWheelCapture={eventDispatchers.onWheelCapture || fn}
                />

                {/* Save/Submit Button */}
                <Button
                    type="button"
                    id={`${id}-save`}
                    variant="outline-secondary"
                    onClick={onSave}
                    style={{ display: displaySave ? "block" : "none" }}
                >
                    Save
                </Button>
            </InputGroup>
            <div className="valid-feedback" id={`${id}-valid-feedback`}>
                {validationMessages.valid || ""}
            </div>
            <div className="invalid-feedback" id={`${id}-invalid-feedback`}>
                {validationMessages.invalid || ""}
            </div>
        </div>
    );
}
