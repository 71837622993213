import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookMedical, faPills, faPlus } from "@fortawesome/free-solid-svg-icons";

const LandingSelect = (props) => {
    return (
        <div id="LandingSelect" className="container p-0 animated fadeIn">
            <div className="d-flex flex-wrap justify-content-center">
                <div
                    className="card"
                    id="medicineManager"
                    style={props.looks.styleDark}
                    onClick={props.onSelect}>
                    <div className="card-title text-center" style={{ color: "white" }}>
                        Add User
                    </div>
                    <div className="card-body" style={{ cursor: "pointer" }}>
                        <span className="d-flex justify-content-center">
                            <FontAwesomeIcon icon={faPills} style={{ color: "white" }} size="10x" />
                            <FontAwesomeIcon icon={faPlus} style={{ color: "white" }} size="2x" />
                        </span>
                    </div>
                </div>
                <div
                    className="card"
                    id="History"
                    style={props.looks.styleDark}
                    onClick={props.onSelect}>
                    <div className="card-title text-center" style={{ color: "white" }}>
                        User History
                    </div>
                    <div className="card-body" style={{ cursor: "pointer" }}>
                        <span className="d-flex justify-content-center">
                            <FontAwesomeIcon
                                icon={faBookMedical}
                                style={{ color: "white" }}
                                size="10x"
                            />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default LandingSelect;
