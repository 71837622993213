import React from "react";

/**
 * Use this function when you need to hide a single element.
 * It requires:
 * The element to be animated,
 * A condition boolean to decide if the animation needs to run
 * The animation that will be used (animations are taken from https://daneden.github.io/animate.css/)
 *
 * @param {*} { element, condition, animation }
 * @returns
 */
const HideSingleAnimation = ({ element, condition, animation }) => {
    /* If given a true value run the animation function */
    if (condition) {
        /* Add the animation to the element */
        element.classList.add(`${animation}`);
        /* Function handles what is done once the animation ends */
        const handleAnimateEnd = () => {
            /* Remove the animation from the element */
            element.classList.remove(`${animation}`);
            /* Remove the element from the DOM */
            element.style.display = "none";
            /* Remove the EventListener from the element */
            element.removeEventListener("animationend", handleAnimateEnd);
        };
        /* Sets an EventListener to the element to fire once the animation ends */
        element.addEventListener("animationend", handleAnimateEnd);
        return;
    }
    return;
};

/**
 * Use this function when you need to hide two elements in sequence
 * It requires:
 * The first element to be animated,
 * The second element to be animated,
 * A condition boolean to decide if animations needs to run,
 * The animation that will be used (animations are taken from https://daneden.github.io/animate.css/)
 *
 * @param {*} {element1, element2, condition, animation }
 * @returns
 */
const HideDoubleAnimation = ({element1, element2, condition, animation }) => {
    /* If given a true value run the animation function */
    if(condition) {
        /* Add the animation to the first element class to be hidden */
        element1.classList.add(`${animation}`);
        /* Add a controller of animation speed to the first element */
        element1.classList.add("fast");
        /* Function that handles what is done once the first element animation is over */
        const handleFirstAnimation = () => {
            /* Remove the animation from the first element class */
            element1.classList.remove(`${animation}`);
            /* Remove the first element from the DOM */
            element1.style.display = "none";
            /* Add the animation to the second element class to be hidden */
            element2.classList.add(`${animation}`);
            /* Add a controller of animation speed to the second element */
            element2.classList.add("fast");
            /* Function that handles what is done once the second element animation is over */
            const handleSecondAnimation = () => {
                /* Remove the animation from the second element class */
                element2.classList.remove(`${animation}`);
                /* Remove the second element from the DOM */
                element2.style.display = "none";
                /* Remove the eventListener from the second element */
                element2.removeEventListener("animationend", handleSecondAnimation);
            };
            /* Add an eventListener to the second element to fire once the animation ends */
            element2.addEventListener("animationend", handleSecondAnimation);
            /* Remove the eventListener from the first element */
            element1.removeEventListener("animationend", handleFirstAnimation);
        };
        /* Add an eventListener to the first element to fire once the animation ends */
        element1.addEventListener("animationend", handleFirstAnimation);
    }
    return;
};

export { HideDoubleAnimation };
export default HideSingleAnimation;
