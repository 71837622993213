import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

const ValidationCheck = ({ validation }) => {
    console.debug(validation);
    const className = `alert alert-${validation.isValid} text-center`;
    return (
        <div className={className} role="alert">
            {/* TODO : Add Title */}
            {/* <div id="alert-title" className="font-weight-bold">TITLE</div> */}
            {/* TODO : Add Close Button Top Right */}
            <div id="alert-body">{validation.message}</div>
        </div>
    );
};

const ValidationSpot = ({ id, warningDisplay }) => {
    //console.debug(warningDisplay);
    return (
        <span id={id}>
            {warningDisplay ? (
                <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: "#EED202" }} />
            ) : (
                <span />
            )}
        </span>
    );
};
export { ValidationSpot };
export default ValidationCheck;
