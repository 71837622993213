import React from "react";
import Password from "./Password";
import { Send } from "pfr-utils";

class DirectLogin extends React.Component {
    constructor(props) {
        super(props);
        this.key = window.location.pathname.replace(/\//g, "");
        this.state = {};
    }

    onSubmit = (event) => {
        const payload = {
            card_id: this.key,
            password: document.getElementById("user-password").value,
        };

        Send("account/direct-login", payload, (response) => {
            console.debug(response);
            if (response === "INVALID_CREDENTIALS")
                alert("Invalid Credentials");

            if (response === "GOTO_ACCOUNT") {
                window.location.href = window.location.href + "account";
                window.location.reload(true);
            }
        });
    };

    render() {
        return (
            <div
                className="d-flex h-100"
                style={{ backgroundColor: " #003333" }}
            >
                {/* Remove h-100 once img is fixed maybe remove d-flex as well */}
                <div className="container d-flex justify-content-center px-0">
                    <div className="card d-flex align-self-center">
                        {/* Might be able to get rid of these three top divs */}
                        <div className="row no-gutters">
                            <div className="col-md-5 d-flex">
                                <div className="d-inline-flex align-items-center">
                                    <img
                                        src="/static/assets/images/StackedLogo.png"
                                        className="img-fluid d-none d-sm-block"
                                        style={{ maxHeight: "100vh" }}
                                    ></img>
                                </div>
                            </div>
                            <div className="card-body row align-items-center">
                                <div className="col">
                                    <h3 className="card-title text-center">
                                        Login
                                    </h3>
                                    <Password />
                                    <input
                                        onClick={this.onSubmit}
                                        className="btn btn-primary btn-lg btn-block"
                                        type="button"
                                        value="Login"
                                        style={{
                                            backgroundColor: "#669999",
                                            borderColor: "#669999",
                                        }}
                                    />
                                </div>
                            </div>

                            {/* <div>TODO : Reset Password</div>
                <div>TODO : Validation Display</div> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DirectLogin;
