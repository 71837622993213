import React from "react";
import { HashRouter, Route, Link } from "react-router-dom";

/* Route abstraction layer to enable adding passing context from top down. */
const PFR_Route = ({ component, ...kwargs }) => {
    /* Helps build route component by mapping props in React.createElement */
    const _buildRender = (component, ...kwargs) => {
        const _mapProps = Object.assign({}, ...kwargs);
        return React.createElement(component, _mapProps);
    };

    /* Return route w/ prop mapping override in render kwarg */
    return (
        <Route
            exact
            {...kwargs}
            render={(routeProps) => {
                return _buildRender(component, routeProps, kwargs);
            }}
        />
    );
};

export default function InputField({ align, id, links, eventDispatchers = {} }) {
    // prettier-ignore
    const fn = () => {return};

    const validLinks = links.filter((link) => {
        return link.isDisplayed;
    });

    const validComponents = links.filter((link) => {
        return link.component;
    });

    return (
        <HashRouter>
            {/* prettier-ignore */}
            <nav id={id} className="navbar bg-dark navbar-dark navbar-expand-sm">
                <ul className="navbar-nav">
                    {validLinks.map(link => (
                        <li className="nav-item">
                            <Link className="nav-link" to={link.path}
                                onClick={link.onClick || fn}>
                                {link.label}
                            </Link>
                        </li>
                    ))}
                </ul>
            </nav>
            <div class="container-fluid h-100 px-0" id="page-content">
                {validComponents.map((link) => (
                    <PFR_Route
                        path={link.path}
                        component={link.component}
                        context={link.context || {}}
                    />
                ))}
            </div>
        </HashRouter>
    );
}
