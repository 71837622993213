import $ from "jquery";
import React from "react";
import Email from "./Email";
import MedicineName from "./MedicineName";
import Password from "./Password";
import PersonalName from "./PersonalName";
import { Send } from "pfr-utils";

class Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    onSubmit = (event) => {
        const payload = {
            key: this.props.context.key,
            create_account: document.getElementById("create-account").checked,
            email: document.getElementById("email").value,
            personal_name: document.getElementById("personal-name").value,
            medicine_name: document.getElementById("medicine-name").value,
            password: document.getElementById("password").value,
        };

        Send("account/register_medicine_profile", payload, (response) => {
            console.debug(response);
            if (response === "GOTO_LOGIN")
                window.location.href = window.location.origin + "/#/login";
        });
    };

    render() {
        return (
            // prettier-ignore
            <form id="register-form" key="registerForm" onSubmit={this.onSubmit} className="d-flex h-100" style={{backgroundColor: "#003333"}}>
                <div className="container d-flex justify-content-center">
                    <div className="card d-flex align-self-center">
                        <div className="row no-gutters">
                            <div className="col-md-4 d-flex ">
                                <div className="d-inline-flex align-items-center">

                                <img src="/static/assets/images/testimg3.jpg"  className="img-fluid d-none d-sm-block"></img>
                                </div>
                            </div>
                            <div className="col-md-8">

                        <div className="card-body">
                            <h3 className="card-title text-center">Registration</h3>
                    <MedicineName props={{ ...this.props }} />
                    <PersonalName props={{ ...this.props }} />
                    <Password props={{ ...this.props }} />
                    <Email props={{ ...this.props }} />

                    {/* prettier-ignore */}
                    <input className="btn btn-primary" 
                    style={{backgroundColor: "#669999", borderColor: "#669999"}}
                        type="button" value="Register" onClick={this.onSubmit}/>
                        </div>
                        </div>
                        </div>
                    </div>
                </div>
{/*                 <hr />

                <h5>Bugs</h5>
                <ul>
                    <li>
                        Password validation message will not display unless both
                        fields are invalid (256+ chars and mismatch)
                    </li>
                    <li>
                        Invalid fields should prevent you from submitting.
                    </li>
                </ul>
                <h5>Extra Features</h5>
                <ul>
                    <li>[+] Add another person => PersonalName</li>
                    <li>Improve form validation</li>
                    <li>_LOOKUPS should be a list of valid keys not just one key to support multiple user accounts.</li>
                    <li>Login should prompt medicine selection if email has multiple keys regiestered</li>
                    <li>You should be able to delete keys when users are "finished" aka throw bottle away</li>
                </ul> */}
            </form>
        );
    }
}

export default Register;
