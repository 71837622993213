import React, { useState } from "react";
import { Render } from "pfr-utils";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faCalendar } from "@fortawesome/free-solid-svg-icons";

const DateTime = ({ looks, screenSize, date, changeDateTime, saveDateTime }) => {
    const render = Render();

    return (
        <div className="form-group">
            <div className="row">
                <div className={screenSize < 768 ? "col-12" : "col"}>
                    <div className="form-group">
                        <label className="mb-0">
                            <span>
                                <span>{"Date"}</span>
                            </span>
                        </label>
                        <br />
                        <div id="date-picker-wrapper-override" className="d-flex">
                            <button
                                className="btn btn-dark rounded-0"
                                id="reset-date"
                                onClick={saveDateTime}>
                                <FontAwesomeIcon icon={faCalendar} />
                            </button>
                            <DatePicker
                                id="date-picker"
                                className="form-control rounded-0"
                                wrapperClassName="flex-fill"
                                selected={date.toDate()}
                                onChange={changeDateTime}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                            />
                        </div>
                    </div>
                </div>
                <div className={screenSize < 768 ? "col-12" : "col"}>
                    <div className="form-group">
                        <label className="mb-0">
                            <span>
                                <span>{"Time"}</span>
                            </span>
                        </label>
                        <br />
                        <div className="d-flex">
                            <button
                                className="btn btn-dark rounded-0"
                                id="reset-time"
                                onClick={saveDateTime}>
                                <FontAwesomeIcon icon={faClock} />
                            </button>
                            <DatePicker
                                selected={date.toDate()}
                                className="form-control rounded-0"
                                wrapperClassName="flex-fill"
                                onChange={changeDateTime}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={10}
                                timeCaption="Time"
                                dateFormat="h:mm aa"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DateTime;
