import React, { useState, useEffect, useRef } from "react";
import DateTime from "./Sections/DateTime";
import Patient from "./Sections/Patient";
import Symptom from "./Sections/Symptoms";
import { Send, Render } from "pfr-utils";
import { ValidationSpot } from "./ValidationCheck";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookMedical, faCog, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

/**
 * Component to add medicine usage to a current/new person and the time taken.
 * Also allows the view of the each users history
 *
 * @param {*} props
 * @returns
 */
const MedicinePage = (props) => {
    //console.warn(props);
    const render = Render();
    const defaults = Object.values(props.symptoms).filter((symptom) => {
        return symptom.isDefault === true;
    });
    const [newUsage, setNewUsage] = useState({
        consumer: props.context.medicine_profile.personal_name,
        symptom: defaults,
        utc_date: moment().format("ddd, DD MMM YYYY HH:mm:ss"),
    });
    const [date, setDate] = useState(moment());
    const [warningDisplay, setWarningDisplay] = useState({
        patient: false,
        symptom: false,
    });

    const changeDateTime = (d) => {
        console.debug(d);
        setDate(moment(d));
        setNewUsage({
            ...newUsage,
            utc_date: moment(d).format("ddd, DD MMM YYYY HH:mm:ss"),
        });
    };

    const saveDateTime = (e) => {
        if (e.currentTarget.id === "reset-date") {
            let resetDate = moment().format("YYYY-MM-DD");
            let currentTime = date.format("HH:mm:ss");
            let newDate = "".concat(resetDate, " ", currentTime);
            setDate(moment(newDate, "YYYY-MM-DD HH:mm:ss"));
            setNewUsage({
                ...newUsage,
                utc_date: moment(newDate, "YYYY-MM-DD HH:mm:ss").format(
                    "ddd, DD MMM YYYY HH:mm:ss"
                ),
            });
            return;
        } else if (e.currentTarget.id === "reset-time") {
            let resetTime = moment().format("HH:mm:ss");
            let currentDate = date.format("YYYY-MM-DD");
            let newTime = "".concat(currentDate, " ", resetTime);
            setDate(moment(newTime, "YYYY-MM-DD HH:mm:ss"));
            setNewUsage({
                ...newUsage,
                utc_date: moment(newTime, "YYYY-MM-DD HH:mm:ss").format(
                    "ddd, DD MMM YYYY HH:mm:ss"
                ),
            });
            return;
        }
        console.warn("error with setting the time");
        return;
    };

    const selectPatient = (d) => {
        let element = document.getElementById("active-record-timestamp");
        document.getElementById("patientBadge").style.display = "";
        const handleAnimateEnd = () => {
            element.classList.remove("fadeIn");
            element.removeEventListener("animationend", handleAnimateEnd);
        };
        setNewUsage({
            ...newUsage,
            consumer: d,
        });
        element.addEventListener("animationend", handleAnimateEnd);
    };

    const removePatient = (d) => {
        let element = document.getElementById("active-record-timestamp");
        const handleAnimateEnd = () => {
            element.classList.remove("fadeOut");
            document.getElementById("patientBadge").style.display = "none";
            setNewUsage({
                ...newUsage,
                consumer: d,
            });
            element.removeEventListener("animationend", handleAnimateEnd);
        };
        element.addEventListener("animationend", handleAnimateEnd);
    };

    const selectSymptom = (e) => {
        document.getElementById("symptomBadge").style.display = "";
        let compareData = newUsage.symptom.find((data) => data.uuid === e.uuid);
        if (compareData === undefined) {
            setNewUsage({
                ...newUsage,
                symptom: [...newUsage.symptom, e],
            });
            return;
        }
    };

    const removeSymptom = (e) => {
        setNewUsage({
            ...newUsage,
            symptom: newUsage.symptom.filter((data) => data.uuid !== e.uuid),
        });
    };

    //console.warn(props.symptoms);
    const addHistory = () => {
        if (newUsage.consumer === "" && newUsage.symptom[0] === undefined) {
            setWarningDisplay({ symptom: true, patient: true });
            props.validation.display = true;
            props.validation.message = "Please select a patient and symptoms";
            props.validation.isValid = "danger";
            props.reRenderParentCallback();
            return;
        } else if (newUsage.consumer === "") {
            setWarningDisplay({ patient: true, symptom: false });
            props.validation.display = true;
            props.validation.message = "Please select a patient";
            props.validation.isValid = "danger";
            props.reRenderParentCallback();
            return;
        } else if (newUsage.symptom[0] === undefined) {
            setWarningDisplay({ patient: false, symptom: true });
            props.validation.display = true;
            props.validation.message = "Please select symptoms";
            props.validation.isValid = "danger";
            props.reRenderParentCallback();
            return;
        }
        setWarningDisplay({ patient: false, symptom: false });
        props.validation.isValid = true;
        props.validation.message = "Success!";
        props.validation.display = "success";
        props.reRenderParentCallback();

        /* Add temp object onto end of history prop */
        //props.context.medicine_profile.history = profile.history.concat(newUsage);
        setNewUsage({
            consumer: "",
            symptom: [],
            utc_date: moment().format("ddd, DD MMM YYYY HH:mm:ss"),
        });
    };

    useEffect(() => {
        let element = document.getElementById("active-record-timestamp");
        const handleAnimateEnd = () => {
            element.classList.remove("fadeIn");
            element.removeEventListener("animationend", handleAnimateEnd);
        };
        element.addEventListener("animationend", handleAnimateEnd);
    }, []);

    return (
        <span>
            <div className="container-fluid animated fadeIn">
                <div className="card mb-4">
                    <div className="card-header">
                        <span
                            id="consumption-entry-header"
                            className="d-flex justify-content-between">
                            <span id="consumption-entry-header-left">
                                <span id="profile-premium">
                                    <span className="mr-1">Registered :</span>
                                    <FontAwesomeIcon
                                        id="profile-registered-disabled"
                                        icon={faTimesCircle}
                                        onClick={(e) => {
                                            alert("Registering Not Implemented Yet");
                                        }}
                                    />
                                </span>
                            </span>
                            <span id="consumption-entry-header-center">
                                <h5>Consumption Entry</h5>
                            </span>
                            <span id="consumption-entry-header-right">
                                <FontAwesomeIcon
                                    id="profile-settings"
                                    className="mr-1"
                                    icon={faCog}
                                    onClick={(e) => {
                                        alert("Settings Not Implemented Yet");
                                    }}
                                />
                                <FontAwesomeIcon
                                    id="profile-history"
                                    icon={faBookMedical}
                                    onClick={(e) => {
                                        alert("History Not Implemented Yet");
                                    }}
                                />
                            </span>
                        </span>
                    </div>
                    <div className="card-body">
                        {/* Add Patient Card */}
                        <Patient
                            looks={props.looks.styleLight}
                            ownerName={props.context.medicine_profile.personal_name}
                            patients={props.patients}
                            screenSize={props.screenSize}
                            selectPatient={selectPatient}
                            removePatient={removePatient}
                            newUsage={newUsage}
                            validation={props.validation}
                            reRenderParentCallback={props.reRenderParentCallback}
                        />

                        {/* Symptoms Dropdown and Save Button */}
                        <Symptom
                            looks={props.looks.styleLight}
                            symptoms={props.symptoms}
                            screenSize={props.screenSize}
                            newUsage={newUsage}
                            selectSymptom={selectSymptom}
                            removeSymptom={removeSymptom}
                            validation={props.validation}
                            reRenderParentCallback={props.reRenderParentCallback}
                        />

                        {/* Timestamp Component */}
                        <DateTime
                            date={date}
                            changeDateTime={changeDateTime}
                            saveDateTime={saveDateTime}
                            looks={props.looks.styleLight}
                            screenSize={props.screenSize}
                            newUsage={newUsage}
                        />
                        <sub className="mt-auto">
                            <button
                                id="saveUserButton"
                                className="btn btn-primary rounded-0"
                                type="button"
                                style={props.looks.styleDark}
                                onClick={addHistory}>
                                Save Usage
                            </button>
                        </sub>
                    </div>
                    {/* Save Button */}

                    <div className="card-footer d-flex flex-column flex-md-row justify-content-around">
                        <span>
                            {/* <strong>{"Patient : "}</strong> */}
                            <span
                                className="badge badge-primary badge-pill rounded-0"
                                style={props.looks.styleLight}>
                                {newUsage.consumer}
                            </span>
                            <ValidationSpot
                                id={"patient"}
                                warningDisplay={warningDisplay.patient}
                            />
                        </span>

                        <span>
                            {/* <strong>{"Date / Time : "}</strong> */}
                            <span
                                className="badge badge-primary badge-pill rounded-0"
                                style={props.looks.styleLight}>
                                {newUsage.utc_date}
                            </span>
                        </span>

                        <span>
                            {/* <strong>{"Symptoms : "}</strong> */}
                            <span>
                                {newUsage.symptom.map((data, index) => {
                                    return (
                                        <span
                                            className="badge badge-primary badge-pill mr-1 rounded-0"
                                            style={props.looks.styleLight}>
                                            {data.label}
                                        </span>
                                    );
                                })}
                                <ValidationSpot
                                    id={"symptom"}
                                    warningDisplay={warningDisplay.symptom}
                                />
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        </span>
    );
};
export default MedicinePage;
