import React, { useState } from "react";
import { InputField } from "templates";
import { InputGroup, div, Form } from "react-bootstrap";
import { faAt } from "@fortawesome/free-solid-svg-icons";
import { Send, isValidEmail } from "pfr-utils";

const ID = "email";

/**
 *
 *
 * @param {*} props
 * @returns
 */
const Email = (props) => {
    // Element Selector Shortcut
    function getElement() {
        return document.getElementById(ID);
    }

    // Ensure all tests pass
    function validationTest() {
        return isValidEmail(getElement().value);
    }

    // States
    const [homeEmail, setHomeEmail] = useState("");

    const [isValid, setIsValid] = useState(true);
    const [validationMessages, setValidationMessages] = useState({
        valid: false,
        invalid: false,
    });

    // Runs validation and manipulates dom with feedback messages based on validation state.
    function runValidation() {
        // Local variable so we can access validation state without waiting for next render tick
        const _isValid = validationTest();

        // Update validation state
        setIsValid(_isValid);

        // Update validation messages
        setValidationMessages({
            valid: false,
            invalid: _isValid ? false : "Invalid Email",
        });

        // Input element
        const element = getElement();

        // Reset input validation border
        element.classList.remove("is-invalid");
        element.classList.remove("is-valid");

        // Set input validation border
        if (!_isValid) element.classList.add("is-invalid");

        // Feedback elements
        const invalid_feedback = document.getElementById(`${ID}-invalid-feedback`); // prettier-ignore
        const valid_feedback = document.getElementById(`${ID}-valid-feedback`);

        // Set feedback display states
        invalid_feedback.style.display = _isValid ? "none" : "block"; // prettier-ignore
        valid_feedback.style.display = "none";
    }

    // Local copy of our submit event so we can access inside of different eventDispatchers
    const _onSubmit = () => {
        runValidation();
    };

    const eventDispatchers = {
        onChange: (event) => {
            runValidation();
        },
    };

    return (
        <div className="py-4">
            <div className="row mr-0">
                {/* This breaks at the smallest screen size into two separate rows
                    it would be better to have this checkbox inside the InputField */}
                <InputGroup.Prepend className="pt-3 mt-3">
                    <div
                        className="input-group-text text-wrap"
                        style={{
                            width: "6rem",
                            fontSize: "0.8rem",
                            lineHeight: "1",
                        }}
                    >
                        Create Account
                        <input
                            id="create-account"
                            type="checkbox"
                            className="ml-2"
                        />
                    </div>
                </InputGroup.Prepend>
                <div className="flex-fill">
                    <InputField
                        id={ID}
                        label="Email"
                        icon={faAt}
                        type="email"
                        defaultValue={homeEmail}
                        eventDispatchers={eventDispatchers}
                        validationMessages={validationMessages}
                    />
                </div>
            </div>
        </div>
    );
};

export default Email;
