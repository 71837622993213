import React from "react";
import Components from "pfr-components";
import { Navigation } from "pfr-templates";

const c = new Components();
const App = ({ context }) => {
    const topNavLinks = [
        {
            component: c.Home,
            context: context,
            label: "Home",
            path: "/",
            isDisplayed: true && window.location.pathname === "/",
        },
        {
            component: c.Account,
            context: context,
            label: "Account",
            path: "/account",
            isDisplayed: context.user.is_authenticated,
        },
        {
            component: c.Login,
            context: context,
            label: "Login",
            path: "/login",
            isDisplayed:
                context.user.is_anonymous && window.location.pathname === "/",
        },
        {
            component: false,
            label: "Logout",
            path: "/logout",
            isDisplayed: context.user.is_authenticated,
            onClick: () => {
                // Redirect to non-hashbang route so flask routing takes over.
                window.location.href = `${window.location.origin}/logout`;
            },
        },
    ];

    // prettier-ignore
    if (context.is_registered !== false 
        && (context.user.is_anonymous || context.user.is_authenticated))
        return (
            <Navigation align="top" id="top-navigation" links={topNavLinks} />
        );

    if (context.is_registered === false) {
        return <c.Register context={context} />;
    }

    // Fail safe (Should not be reached)
    return (
        <div>
            <h1 className="text-center mt-2">
                Critical context.user Session Error.
            </h1>
            <hr />
            <h3 className="text-center">
                <p>Please contact</p>
                <a href="mailto:development@plasticprinters.com">
                    Development@PlasticPrinters.com
                </a>
            </h3>
        </div>
    );
};

export default App;
