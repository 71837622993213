import React from "react";
import MedicinePage from "./MedicineManagerPage";
import MedicineHistory from "./MedicineHistory";
import LandingSelect from "./LandingSelect";
import ValidationCheck from "./ValidationCheck";

class MedicineManager extends React.Component {
    constructor(props) {
        super(props);
        this.reRenderParentCallback = this.reRenderParentCallback.bind(this);
        this.state = {
            validation: { message: "", display: false, isValid: false },
            pageSelect: "",
            screenSize: 7680,
        };
        this.user = this.props.user;
    }
    componentDidMount = () => {
        this.setState({
            screenSize: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
        });
        window.addEventListener("resize", this.updateDimensions);
    };

    reRenderParentCallback = () => {
        this.forceUpdate();
    };

    componentWillUnmount = () => {
        window.removeEventListener("resize", this.updateDimensions);
    };
    updateDimensions = () => {
        this.setState({
            screenSize: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
        });
    };

    onSelect = (event) => {
        this.setState({
            pageSelect: event.currentTarget.id,
            hideTopNav: false,
        });
        this.setState((prevState) => ({
            validation: {
                ...prevState.validation,
                message: "",
                display: false,
                isValid: "",
            },
        }));
    };

    render() {
        return (
            <div className="container px-0" key="medicineManagerPage">
                {this.state.validation.display ? (
                    <ValidationCheck validation={this.state.validation} />
                ) : (
                    <span />
                )}
                <div className="row no-gutters h-100">
                    <div className="col align-self-center">
                        <span>
                            {this.state.pageSelect === "medicineManager" ? (
                                <MedicinePage
                                    context={this.props.context}
                                    looks={this.props.style}
                                    patients={this.props.context.medicine_profile.patients || []}
                                    symptoms={this.props.context.medicine_profile.symptoms || []}
                                    onSelect={this.onSelect}
                                    screenSize={this.state.screenSize}
                                    validation={this.state.validation}
                                    reRenderParentCallback={this.reRenderParentCallback}
                                />
                            ) : this.state.pageSelect === "History" ? (
                                <MedicineHistory
                                    context={this.props.context}
                                    looks={this.props.style}
                                    onSelect={this.onSelect}
                                />
                            ) : (
                                <LandingSelect looks={this.props.style} onSelect={this.onSelect} />
                            )}
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

export default MedicineManager;
