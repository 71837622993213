import React, { useState } from "react";
import { InputField } from "templates";
import { faKey, faLock } from "@fortawesome/free-solid-svg-icons";

const ID = "password";
const secondaryID = "password-confirm";

const Password = (props) => {
    // Element Selector Shortcut
    function getElement() {
        return document.getElementById(ID);
    }

    function getSecondaryElement() {
        return document.getElementById(secondaryID);
    }

    // Ensure all tests pass
    function validationTest() {
        return getElement().value.length <= 256;
    }

    // Ensure all tests pass
    function secondaryValidationTest() {
        return (
            getSecondaryElement().value.length <= 256 &&
            getElement().value === getSecondaryElement().value
        );
    }

    // States
    const [medicineName, setMedicineName] = useState("");
    const [isValid, setIsValid] = useState(true);
    const [isSecondaryValid, setSecondaryIsValid] = useState(true);
    const [validationMessages, setValidationMessages] = useState({
        valid: false,
        invalid: false,
    });
    const [
        secondaryValidationMessages,
        setSecondaryValidationMessages,
    ] = useState({
        valid: false,
        invalid: false,
    });

    // Runs validation and manipulates dom with feedback messages based on validation state.
    function runValidation() {
        // Local variable so we can access validation state without waiting for next render tick
        const _isValid = validationTest();
        const _isSecondaryValid = secondaryValidationTest();

        // Update validation state
        setIsValid(_isValid);
        setSecondaryIsValid(_isSecondaryValid);

        // Update validation messages
        setValidationMessages({
            valid: false,
            invalid: _isValid ? false : "Password too long (Max 256 chars)",
        });

        // Update validation messages
        setSecondaryValidationMessages({
            valid: false,
            invalid: _isValid ? false : "Passwords do not match",
        });

        // Input element
        const element = getElement();
        const secondaryElement = getSecondaryElement();

        // Reset input validation border
        element.classList.remove("is-invalid");
        secondaryElement.classList.remove("is-invalid");
        element.classList.remove("is-valid");
        secondaryElement.classList.remove("is-valid");

        // Set input validation border
        if (!_isValid) element.classList.add("is-invalid");
        if (!_isSecondaryValid) secondaryElement.classList.add("is-invalid");

        // Feedback elements
        const invalid_feedback = document.getElementById(`${ID}-invalid-feedback`); // prettier-ignore
        const valid_feedback = document.getElementById(`${ID}-valid-feedback`);
        const secondary_invalid_feedback = document.getElementById(`${secondaryID}-invalid-feedback`); // prettier-ignore
        const secondary_valid_feedback = document.getElementById(
            `${secondaryID}-valid-feedback`
        );

        // Set feedback display states
        invalid_feedback.style.display = _isValid ? "none" : "block"; // prettier-ignore
        valid_feedback.style.display = "none";
        secondary_invalid_feedback.style.display = _isValid ? "none" : "block"; // prettier-ignore
        secondary_valid_feedback.style.display = "none";
    }

    const eventDispatchers = {
        onChange: (event) => {
            runValidation();
        },
    };

    return (
        <div key="registrationPasswordFields">
            <div className="pt-4">
                <InputField
                    type="password"
                    id={ID}
                    label="Medicine Password"
                    icon={faKey}
                    eventDispatchers={eventDispatchers}
                    validationMessages={validationMessages}
                />
            </div>
            <div className="pt-4">
                <InputField
                    type="password"
                    id={secondaryID}
                    label="Confirm Medicine Password"
                    icon={faLock}
                    eventDispatchers={eventDispatchers}
                    validationMessages={secondaryValidationMessages}
                />
            </div>
        </div>
    );
};

export default Password;
