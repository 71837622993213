import React from "react";

class Settings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return <div>Hello from Settings.</div>;
    }
}

export default Settings;
