import React, { useState, useEffect, useLayoutEffect } from "react";
import DeleteModal from "../DeleteModal";
import { ShowDoubleAnimation } from "../Animations/ShowAnimation";
import { HideDoubleAnimation } from "../Animations/HideAnimation";
import { Render, RenderWithReload, Send } from "pfr-utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faTimes, faPlus } from "@fortawesome/free-solid-svg-icons";

const Symptom = ({
    looks,
    symptoms,
    newUsage,
    selectSymptom,
    removeSymptom,
    validation,
    reRenderParentCallback,
}) => {
    const render = Render();
    const [selectedSymptom, setSelectedSymptom] = useState(newUsage.symptom.map((d) => d.label));
    const [symptomList, setSymptomList] = useState(symptoms);
    const [deleteTarget, setDeleteTarget] = useState({ label: "", uuid: "" });
    const [inputDisplay, setInputDisplay] = useState(false);
    const [animationHandler, setAnimationHandler] = useState("");

    const deleteSymptom = (e) => {
        validation.display = true;
        validation.message = "Symptom deleted";
        validation.isValid = "warning";

        const profileKey = window.location.pathname.replace(/\//g, "");
        const payload = {
            profileKey: profileKey,
            deleteTarget: deleteTarget,
        };

        Send("medicine-profile/delete-symptom", payload, (response) => {
            console.debug(response);
        });

        let clonedState = { ...symptomList };
        delete clonedState[deleteTarget.uuid];
        setSymptomList(clonedState);

        removeSymptom(deleteTarget);
        reRenderParentCallback();
    };

    const addSymptom = (e) => {
        document.getElementById("newSymptom").classList.remove("is-invalid");
        const _symptoms = Object.values(symptomList);
        const symptomName = document.getElementById("newSymptom").value;

        const labelExists = (symptom) => {
            return symptom.label.toLocaleLowerCase() === symptomName.toLocaleLowerCase();
        };
        const invalidLabel = _symptoms.some(labelExists);

        if (invalidLabel || symptomName.length === 0 || symptomName.length > 255) {
            document.getElementById("newSymptom").classList.add("is-invalid");
            validation.display = true;
            validation.message = invalidLabel
                ? "Symptom already exists."
                : "Symptom name must be longer than 0 characters and shorter than 255.";
            validation.isValid = "danger";
            reRenderParentCallback();
            return;
        }

        const payload = {
            profileKey: window.location.pathname.replace(/\//g, ""),
            symptomName: symptomName,
        };
        validation.display = true;
        validation.message = "New symptom added.";
        validation.isValid = "success";
        reRenderParentCallback();

        Send("medicine-profile/add-symptom", payload, (response) => {
            console.debug(response);
            if (response.result === "success") {
                // Add UI Element from response or add to props to auto render.
                const newSymptom = response.newSymptom;
                symptoms = { ...symptoms, ...newSymptom };
                selectSymptom(Object.values(newSymptom)[0]);
                setSymptomList({ ...symptomList, ...newSymptom });
            }
        });
        render();
        document.getElementById("newSymptom").value = "";
        setInputDisplay(false);
    };

    const checkDefault = (e) => {
        const uuid = e.target.dataset.uuid;

        const symptom = symptomList[uuid] || false;
        if (symptom) {
            // FIXME : Update checkbox state right away

            const payload = {
                profileKey: window.location.pathname.replace(/\//g, ""),
                symptom: {
                    uuid: uuid,
                    isDefault: !symptom.isDefault,
                },
            };

            Send("medicine-profile/update-symptom-default", payload, (response) => {
                console.debug(response);
                if (response.result === "success") {
                    const label = { label: symptom.label };
                    const updatedSymptom = { ...response.symptom, ...label };
                    setSymptomList({ ...symptomList, [uuid]: updatedSymptom });
                    return;
                }
            });
            return !symptom.isDefault;
        }

        const warning = "Invalid Symptom UUID";
        console.warn(warning);
        validation.display = true;
        validation.message = "Error Occured. Please refresh page and try again.";
        validation.isValid = "danger";
        reRenderParentCallback();
        return warning;
    };

    const showAnimation = () => {
        const element1 = document.getElementById("newSymptom");
        const element2 = document.getElementById("symptomAdd");
        const condition = element1.style.display === "none";
        const animation = "slideInRight";
        ShowDoubleAnimation({ element1, element2, condition, animation });
        setInputDisplay(true);
    };

    const hideAnimation = () => {
        const element1 = document.getElementById("symptomAdd");
        const element2 = document.getElementById("newSymptom");
        const condition = element1.style.display === "";
        const animation = "slideOutRight";
        HideDoubleAnimation({ element1, element2, condition, animation });
        setInputDisplay(false);
    };

    useEffect(() => {
        //console.debug("selected symptom changed", newUsage);
        setSelectedSymptom(newUsage.symptom.map((d) => d.label));
    }, [newUsage.symptom]);

    const removeSymptomBadge = (e, data) => {
        let element = document.querySelectorAll(`[data-symptomLabel="${data}"]`)[0];
        let symptomData = newUsage.symptom.filter((d) => d.label === data);
        const handleAnimateEnd = () => {
            element.classList.remove("fadeOut");
            removeSymptom(symptomData[0]);
            setSelectedSymptom(selectedSymptom.filter((d) => d !== data));
            element.removeEventListener("animationend", handleAnimateEnd);
        };
        element.addEventListener("animationend", handleAnimateEnd);
    };

    return (
        <span>
            <h5 className="d-flex flex-wrap">
                Current Symptoms
                {/* Symptom Badges */}
                <div id="symptomBadge" style={{ display: selectSymptom[0] === "" ? "none" : "" }}>
                    {selectedSymptom.map((data) => {
                        if (data !== "") {
                            return (
                                <span
                                    className="badge badge-primary ml-2 rounded-0 font-weight-normal animated fadeIn"
                                    style={looks}
                                    data-symptomLabel={data}>
                                    {data}
                                    <sup className="pl-1">
                                        <span
                                            className="deleteButton symptom-badge"
                                            data-id={data}
                                            onClick={(event) => {
                                                document
                                                    .querySelectorAll(
                                                        `[data-symptomLabel="${data}"]`
                                                    )[0]
                                                    .classList.add("fadeOut");
                                                document
                                                    .querySelectorAll(
                                                        `[data-symptomLabel="${data}"]`
                                                    )[0]
                                                    .classList.add("faster");
                                                removeSymptomBadge(event, data);
                                            }}
                                            style={{
                                                fontSize: "small",
                                                cursor: "pointer",
                                            }}>
                                            <FontAwesomeIcon
                                                className="mt-1 ml-1"
                                                icon={faTimesCircle}
                                            />
                                        </span>
                                    </sup>
                                </span>
                            );
                        }
                    })}
                </div>
            </h5>
            <span className="d-flex flex-wrap mb-1">
                {Object.values(symptomList).map((data, index) => {
                    if (data.label !== "") {
                        return (
                            <span className="btn-group mt-1  mr-1" role="group">
                                <button
                                    type="button"
                                    className="btn btn-secondary d-inline-flex align-items-center px-1 rounded-0"
                                    style={looks}>
                                    <input
                                        type="checkbox"
                                        className="defaultBox"
                                        data-uuid={data.uuid}
                                        onClick={checkDefault}
                                        checked={data.isDefault}
                                    />
                                </button>
                                <button
                                    className="btn btn-primary rounded-0 px-1 symptom setButton"
                                    id={index}
                                    style={looks}
                                    data-uuid={data.uuid}
                                    data-label={data.label}
                                    data-default={data.isDefault}
                                    type="button"
                                    onClick={(e) => {
                                        let selectedSymptom = {
                                            isDefault: e.target.dataset.default,
                                            label: e.target.dataset.label,
                                            uuid: e.target.dataset.uuid,
                                        };
                                        selectSymptom(selectedSymptom);
                                    }}>
                                    {data.label}
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-primary rounded-0 px-1"
                                    style={looks}>
                                    <span
                                        className="pr-1"
                                        data-toggle="modal"
                                        data-target="#delete-modal-symptoms"
                                        data-uuid={data.uuid}
                                        data-label={data.label}
                                        onClick={(e) => {
                                            setDeleteTarget({
                                                label: e.currentTarget.dataset.label,
                                                uuid: e.currentTarget.dataset.uuid,
                                            });
                                        }}>
                                        <sup className="">
                                            <span
                                                className="deleteButton"
                                                data-uuid={data.uuid}
                                                data-label={data.label}
                                                id={data.uuid}
                                                style={{
                                                    color: "white",
                                                    fontSize: "small",
                                                }}>
                                                <FontAwesomeIcon
                                                    className="mt-1 ml-1"
                                                    icon={faTimesCircle}
                                                />
                                            </span>
                                        </sup>
                                    </span>
                                </button>
                            </span>
                        );
                    }
                    return;
                })}
            </span>
            <div className="d-flex">
                <div className="input-group mt-1">
                    <div className="input-group-prepend" style={{ zIndex: "1" }}>
                        <button
                            className="btn btn-primary rounded-0"
                            type="button"
                            onClick={(e) => {
                                inputDisplay ? hideAnimation(e) : showAnimation(e);
                                document
                                    .getElementById("newSymptom")
                                    .classList.remove("is-invalid");
                            }}
                            style={looks}>
                            <FontAwesomeIcon icon={inputDisplay ? faTimes : faPlus} size="sm" />
                            <span className="ml-1" id="symptomInputDisplay">
                                {inputDisplay ? "Close" : "Add"}
                            </span>
                        </button>
                    </div>
                    <input
                        autoComplete="off"
                        className="form-control rounded-0 animated"
                        id="newSymptom"
                        type="text"
                        placeholder="Another Symptom"
                        style={{ display: "none" }}
                        onKeyUp={(e) => {
                            if (e.keyCode === 13) {
                                addSymptom(e);
                            }
                        }}
                    />
                    <div className="input-group=append">
                        <button
                            id="symptomAdd"
                            className="btn btn-primary rounded-0 animated"
                            style={{ ...looks, display: "none" }}
                            onClick={addSymptom}>
                            <FontAwesomeIcon icon={faPlus} size="sm" />
                            <span className="ml-1">Add</span>
                        </button>
                    </div>
                    <div className="invalid-feedback">Needs a symptom</div>
                </div>
            </div>
            <DeleteModal id="symptoms" deleteEvent={deleteSymptom} deleteTarget={deleteTarget} />
        </span>
    );
};
export default Symptom;
