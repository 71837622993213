import React, { useState } from "react";
import { InputField } from "templates";
import { faPrescription } from "@fortawesome/free-solid-svg-icons";

const ID = "medicine-name";

const MedicineName = (props) => {
    // Element Selector Shortcut
    function getElement() {
        return document.getElementById(ID);
    }

    // Ensure all tests pass
    function validationTest() {
        return getElement().value.length <= 256;
    }

    // States
    const [medicineName, setMedicineName] = useState("");
    const [isValid, setIsValid] = useState(true);
    const [validationMessages, setValidationMessages] = useState({
        valid: false,
        invalid: false,
    });

    // Runs validation and manipulates dom with feedback messages based on validation state.
    function runValidation() {
        // Local variable so we can access validation state without waiting for next render tick
        const _isValid = validationTest();

        // Update validation state
        setIsValid(_isValid);

        // Update validation messages
        setValidationMessages({
            valid: false,
            invalid: _isValid ? false : "Name too long (Max 256 chars)",
        });

        // Input element
        const element = getElement();

        // Reset input validation border
        element.classList.remove("is-invalid");
        element.classList.remove("is-valid");

        // Set input validation border
        if (!_isValid) element.classList.add("is-invalid");

        // Feedback elements
        const invalid_feedback = document.getElementById(`${ID}-invalid-feedback`); // prettier-ignore
        const valid_feedback = document.getElementById(`${ID}-valid-feedback`);

        // Set feedback display states
        invalid_feedback.style.display = _isValid ? "none" : "block"; // prettier-ignore
        valid_feedback.style.display = "none";
    }

    const eventDispatchers = {
        onChange: (event) => {
            runValidation();
        },
    };

    return (
        <div className="pt-1">
            {/* <pre>{JSON.stringify(props, undefined, 2)}</pre> */}
            <InputField
                id={ID}
                label="Medicine Name"
                icon={faPrescription}
                defaultValue={medicineName}
                eventDispatchers={eventDispatchers}
                validationMessages={validationMessages}
            />
        </div>
    );
};

export default MedicineName;
