import React from "react";
import MedicineManager from "./MedicineManager";

class Account extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            style: {
                styleLight: {
                    backgroundColor: "#669999",
                    borderColor: "#669999",
                },
                styleDark: {
                    backgroundColor: "#003333",
                    borderColor: "#003333",
                },
            },
        };
    }
    render() {
        return (
            <div
                className="d-flex h-100"
                style={this.state.style.styleDark}
                key="accountInformation"
            >
                <MedicineManager
                    context={this.props.context}
                    style={this.state.style}
                />
            </div>
        );
    }
}

export default Account;
