import React, { useState } from "react";
import urlRegex from "url-regex";

function Send(relativeURI, payload, onSuccess, method = "PUT") {
    if (document.getElementById("csrf_token") === null)
        throw new Error(
            "PFR =>\n\tCSRF Token Not Found in DOM.\n\t" +
                "Developers Note : Please ensure you are inside of jinja context.\n\t"
        );

    try {
        fetch(`${window.location.origin}/api/resources/${relativeURI}`, {
            method: method,
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": document.getElementById("csrf_token").value,
            },
            body: JSON.stringify(payload),
        })
            .then((response) => response.json())
            .then((data) => onSuccess(data));
    } catch (e) {
        console.error(`utils.Send() =>\n\t${e}`);
    }
}

async function ASend(relativeURI, payload, method = "PUT") {
    //     const response = await fetch(
    //         `${window.location.origin}/api/resources/${relativeURI}`,
    //         {
    //             method: "POST",
    //             headers: { "Content-Type": "application/json" },
    //             body: JSON.stringify({ test: "t" })
    //         }
    //     );
    //     let data;
    //     try {
    //         data = (await response.json()) || {};
    //     } catch (error) {
    //         console.error(`utils.ASend().data =>\n\t${error}`);
    //     }
    //     console.log(data);
}

/** Render
 *
 * set as variable inside of stateless function
 * and call that object as a function to force a re-render of the component
 *
 * @returns fn to force component re-render
 */
function Render(iterations = 2) {
    const [value, setValue] = useState(0);
    return () => {
        for (let i = 0; i < iterations; i++) {
            setValue((value) => ++value);
        }
    };
}

function RenderWithReload(iterations = 2) {
    const [value, setValue] = useState(0);
    return () => {
        for (let i = 0; i < iterations; i++) {
            setValue((value) => ++value);
        }
    };
    window.location.reload(true);
}

function isValidEmail(str) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(str).toLowerCase());
}

function isValidURL(str) {
    return urlRegex({ exact: true }).test(str);
}

export { ASend, Send, Render, RenderWithReload, isValidEmail, isValidURL };
