import React from "react";
import DirectLogin from "../Login/DirectLogin";

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.key = window.location.pathname.replace(/\//g, "");
        this.state = {};
        this.user = this.props.context.user;
    }

    render() {
        if (this.key !== "" && this.user.is_anonymous)
            return (
                <div>
                    <DirectLogin />
                </div>
            );
        // else if (this.key === "" && this.user.is_authenticated) {
        //     window.location.href = window.location.href + "account";
        //     window.location.reload(true);
        // }

        // Default Home
        return (
            <div key="homePage">
                <pre>
                    DUMMY DATA{"\n"}
                    this.props.context :{" "}
                    {JSON.stringify(this.props.context, undefined, 2)}
                </pre>
            </div>
        );
    }
}

export default Home;
