import React, { useState } from "react";
import { InputField } from "templates";
import { faKey } from "@fortawesome/free-solid-svg-icons";
import { Send } from "pfr-utils";

const ID = "user-password";

const Password = (props) => {
    // Element Selector Shortcut
    function getElement() {
        return document.getElementById(ID);
    }

    // Ensure all tests pass
    function validationTest() {
        return getElement().value.length <= 256;
    }

    // States
    const [isValid, setIsValid] = useState(true);
    const [validationMessages, setValidationMessages] = useState({
        valid: false,
        invalid: false,
    });

    // Runs validation and manipulates dom with feedback messages based on validation state.
    function runValidation() {
        // Local variable so we can access validation state without waiting for next render tick
        const _isValid = validationTest();

        // Update validation state
        setIsValid(_isValid);

        // Update validation messages
        setValidationMessages({
            valid: false,
            invalid: _isValid ? false : "Password Too Long (Max 256)",
        });

        // Input element
        const element = getElement();

        // Reset input validation border
        element.classList.remove("is-invalid");
        element.classList.remove("is-valid");

        // Set input validation border
        if (!_isValid) element.classList.add("is-invalid");

        // Feedback elements
        const invalid_feedback = document.getElementById(`${ID}-invalid-feedback`); // prettier-ignore
        const valid_feedback = document.getElementById(`${ID}-valid-feedback`);

        // Set feedback display states
        invalid_feedback.style.display = _isValid ? "none" : "block"; // prettier-ignore
        valid_feedback.style.display = "none";
    }

    // Local copy of our submit event so we can access inside of different eventDispatchers
    const _onSubmit = () => {
        runValidation();
    };

    const eventDispatchers = {
        onChange: (event) => {
            runValidation();
        },
        onSubmit: _onSubmit,
    };

    return (
        <div className="pb-4">
            <InputField
                id={ID}
                label="Password"
                icon={faKey}
                type="password"
                eventDispatchers={eventDispatchers}
                validationMessages={validationMessages}
            />
        </div>
    );
};

export default Password;
