import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookMedical, faPills, faChevronLeft } from "@fortawesome/free-solid-svg-icons";

const MedicineHistory = (props) => {
    let test = props.context.medicine_profile.history;
    console.log(test);

    return (
        <div className="container justify-content-center align-items-center p-0 animated fadeIn">
            <div className="card">
                <div className="card-header align-items-center row">
                    <span className="col" />
                    <h3 className="col-8 text-center">Usage History</h3>
                    <span className="col-3">
                        <button
                            type="button"
                            className="nav-link btn btn-primary"
                            id="medicineManager"
                            onClick={props.onSelect}
                            style={props.looks.styleLight}>
                            <FontAwesomeIcon
                                icon={faPills}
                                size="lg"
                                style={{ color: "white" }}
                                fixedWidth
                            />
                            <span className="ml-2">Record Usage</span>
                        </button>
                    </span>
                </div>
                <div className="card-body">
                    <ul className="list-group">
                        {test.map((data) => {
                            let date = data.utc_date.split(" ");
                            return (
                                <li className="list-group-item">
                                    <span
                                        className="badge badge-primary badge-pill mr-2"
                                        style={props.looks.styleLight}>
                                        {data.consumer}
                                    </span>
                                    <span style={{ fontWeight: "bold" }}>Taken at: </span>
                                    <span className="mr-2">
                                        {date[2]} {date[1]} {date[3]} {date[4].slice(0, 5)}
                                    </span>
                                    <span style={{ fontWeight: "bold" }}>Taken for: </span>
                                    <span>{data.symptom}</span>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </div>
    );
};
export default MedicineHistory;
